import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { GenericErrorDialogComponent } from '../generic-error-dialog/generic-error-dialog.component';
import { ProblemResponse } from '../generic-error-dialog/common/problem-response.type';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private dialogService: MatDialog,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map(
				/**
				 * could log if desired
				 * if (event instanceof HttpResponse) { Log! } return event;
				 */
				(event: HttpEvent<any>) => event,
			),
			catchError((error: HttpErrorResponse) => {
				console.error('Transport Error', error);
				const status = error.status ? error.status : error.error.message;
				let message = error!.message;
				const problemResponse = error.error as ProblemResponse;
				if (problemResponse != null) {
					message = problemResponse.title;
				}
				if (isPlatformBrowser(this.platformId)) {
					this.dialogService.open(GenericErrorDialogComponent, {
						autoFocus: false,
						data: {
							title: 'Error With Request',
							message: `Error ${status}: ${message}`,
						},
					});
				}
				return throwError(error);
			}),
		);
	}
}
